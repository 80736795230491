.img-1 {
  background-image: url(../image/github.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.img-2 {
  background-image: url(../image/LinkedIn.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.img-3 {
  background-image: url(../image/medium.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}
